<template>
  <v-container>
    <h3>Usuário</h3>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="me.name"
          label="Nome (usuário)"
          hide-details="auto"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="me.email"
          label="Email"
          hide-details="auto"
          disabled
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="me.password"
          :append-icon="passShow ? 'fas fa-eye' : 'fas fa-eye-slash'"
          :type="passShow ? 'text' : 'password'"
          name="password"
          label="Nova senha"
          hint="Indique a nova senha"
          @click:append="passShow = !passShow"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn color="success" @click="updateMe()">Salvar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  components: {},
  data() {
    return {
      me: {},
      passShow: false
    }
  },
  methods: {
    getMe() {
      var vm = this
      vm.$API
        .Request('get', `me`)
        .then(result => {
          console.debug(result)
          vm.me = result
        })
        .catch(error => {
          console.debug(error)
        })
    },
    updateMe() {
      var vm = this
      vm.$API
        .Request('post', `me/update`, vm.me)
        .then(result => {
          console.debug(result)
          if (result && result.message) {
            if (result.code == 200) {
              vm.$snotify.success(result.message)
            } else {
              vm.$snotify.warning(result.message)
            }
          }
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    }
  },
  created() {
    var vm = this
    vm.getMe()
  },
  mounted() {}
}
</script>
